import React from "react";
import { Header } from "./header";
import { Contact } from "./contact";
import { Navigation } from "./navigation";
import { db } from "../firebase"; // Import the db from your firebase.js
import { ref, set } from "firebase/database"; // Import ref and set

const Admin = ({ isLoggedIn }) => {
    const [title, setTitle] = React.useState("");
    const [date, setDate] = React.useState("");
    const [content, setContent] = React.useState("");
    const [button1Name, setButton1Name] = React.useState("");
    const [button1Url, setButton1Url] = React.useState("");
    const [button2Name, setButton2Name] = React.useState("");
    const [button2Url, setButton2Url] = React.useState("");
    const contactData = {
        addresses: [
            "1) DLF Cybercity, Gurugram, India",
            "2) Unit 207A, 8 Cowper Street Parramatta NSW 2150 Australia",
            "3) California, USA"
        ],
        phone: "+91 97192 53679",
        email: "consult@marcomfreelancers.com",
        facebook: "fb.com",
        twitter: "twitter.com",
        linkedin: "linkedin.com"
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const newPost = {
            title,
            date,
            content,
            button1: { name: button1Name, url: button1Url },
            button2: { name: button2Name, url: button2Url }
        };

        try {
            const postRef = ref(db, 'posts/' + new Date().getTime()); // Create a unique key based on the current timestamp
            await set(postRef, newPost); // Add the post to the Realtime Database

            alert("Blog post added successfully!");
            // Resetting the form
            setTitle("");
            setDate("");
            setContent("");
            setButton1Name("");
            setButton1Url("");
            setButton2Name("");
            setButton2Url("");
        } catch (error) {
            alert("Failed to add blog post: " + error.message);
        }
    };

    if (!isLoggedIn) {
        return <p>You must be logged in to add a blog post.</p>;
    }

    return (
        <div>
            <Navigation />
            <Header />
            <div style={{ padding: "30px", maxWidth: "600px", margin: "0 auto", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" }}>
                <h2 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>Add New Blog Post</h2>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "20px" }}>
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <textarea
                            placeholder="Content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", height: "150px", resize: "none" }}
                        />
                    </div>

                    {/* Button 1 Name and URL */}
                    <div style={{ marginBottom: "20px" }}>
                        <input
                            type="text"
                            placeholder="Button 1 Name"
                            value={button1Name}
                            onChange={(e) => setButton1Name(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}
                        />
                        <input
                            type="url"
                            placeholder="Button 1 URL"
                            value={button1Url}
                            onChange={(e) => setButton1Url(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", marginTop: "10px" }}
                        />
                    </div>

                    {/* Button 2 Name and URL */}
                    <div style={{ marginBottom: "20px" }}>
                        <input
                            type="text"
                            placeholder="Button 2 Name"
                            value={button2Name}
                            onChange={(e) => setButton2Name(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)" }}
                        />
                        <input
                            type="url"
                            placeholder="Button 2 URL"
                            value={button2Url}
                            onChange={(e) => setButton2Url(e.target.value)}
                            required
                            style={{ width: "100%", padding: "12px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", marginTop: "10px" }}
                        />
                    </div>

                    <button type="submit" style={{ padding: "12px 20px", borderRadius: "5px", border: "none", backgroundColor: "#6372ff", color: "#fff", fontSize: "16px", cursor: "pointer", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", transition: "background-color 0.3s ease" }}>
                        Add Blog Post
                    </button>
                </form>
            </div>
            <Contact data={contactData} />
        </div>
    );
};

export default Admin;
