import React from 'react';
import { useParams } from 'react-router-dom';
import { Header } from "./header";
import { Contact } from "./contact";
import { Navigation } from "./navigation";

const BlogDetail = ({ posts }) => {
  const { id } = useParams(); // Get the post ID from the URL
  const post = posts.find(p => p.id === id); // Find the corresponding post
  const headerData = {
    title: "",
    paragraph: "",
  };
  const contactData = {
    addresses: [
      "1) DLF Cybercity, Gurugram, India",
      "2) Unit 207A, 8 Cowper Street Parramatta NSW 2150 Australia",
      "3) California, USA"
    ],
    phone: "+91 97192 53679",
    email: "consult@marcomfreelancers.com",
    facebook: "fb.com",
    twitter: "twitter.com",
    linkedin: "https://www.linkedin.com/company/marcomfreelancers/about/?viewAsMember=true"
  };


  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div>
      <Navigation />
      <Header />
      <main style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <h2>{post.title}</h2>
        {/* <img src={post.imageUrl} alt={post.title} style={{ width: '100%', borderRadius: '10px' }} /> */}
        <p><i>{post.date}</i></p>
        <p>{post.content}</p>
        {post.button1 && (
          <a href={post.button1.url} style={{ textDecoration: 'none' }}>
            <button style={{ margin: '10px', padding: '10px 20px', backgroundColor: '#6372ff', color: '#fff', border: 'none', borderRadius: '5px' }}>
              {post.button1.name}
            </button>
          </a>
        )}
        {post.button2 && (
          <a href={post.button2.url} style={{ textDecoration: 'none' }}>
            <button style={{ margin: '10px', padding: '10px 20px', backgroundColor: '#6372ff', color: '#fff', border: 'none', borderRadius: '5px' }}>
              {post.button2.name}
            </button>
          </a>
        )}
      </main>
      <Contact data={contactData} />
    </div>
  );
};

export default BlogDetail;
