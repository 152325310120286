import React, { useRef } from "react";

const scrollAmount = 300; // Amount to scroll

const scroll = (ref, direction) => {
  if (ref.current) {
    ref.current.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth',
    });
  }
};

const scrollableRowStyle = {
  display: 'flex',
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  padding: '10px 0',
  scrollbarWidth: 'none',
  alignItems: 'center', // Center items vertically
};

const featureItemStyle = {
  flex: '0 0 auto',
  width: '300px', // Same width as in Gallery
  margin: '0 10px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  overflow: 'hidden',
  textAlign: 'center',
};

const imageStyle = {
  width: '150px',
  height: '150px',
  objectFit: 'contain',
  marginTop: '10px',
};

const buttonStyle = {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  fontSize: '24px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
};

const leftButtonStyle = {
  ...buttonStyle,
  left: '0',
  marginLeft: '10px',
};

const rightButtonStyle = {
  ...buttonStyle,
  right: '0',
  marginRight: '10px',
};

export const Features = (props) => {
  const containerRef = useRef(null);

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={{color:"#000", fontWeight: "800", marginTop:"30px"}}>Our Expertise</h2>
          <p style={{color:"#000"}}>
            We bring a wealth of experience across various domains, ensuring tailored solutions for your needs.
          </p>
        </div>
        <div className="row" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <button onClick={() => scroll(containerRef, 'left')} style={leftButtonStyle}>
            &lt; {/* Left Arrow */}
          </button>
          <div className="scroll-container" ref={containerRef} style={scrollableRowStyle}>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} style={featureItemStyle}>
                    <img src={d.icon} alt={d.title} style={imageStyle} />
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
          <button onClick={() => scroll(containerRef, 'right')} style={rightButtonStyle}>
            &gt; {/* Right Arrow */}
          </button>
        </div>
      </div>
    </div>
  );
};
