import React from "react";

export const About = (props) => {
  return (
    <div id="about" className="text-center" style={{ backgroundColor: "#6372ff", color: "#fff", padding: "10px 0", marginTop:"30px" }}>
      <div className="container">
        <h2 style={{color:"#fff", marginTop:'20px'}}>Why People Choose Us</h2>
        <div className="row" style={{ marginTop: "30px" }}>
          {/* Icon and Description Columns */}
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-check-circle" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Credibility</h4>
              <p>
                We verify Freelancers, publish their feedback scores, and All-Time Transaction Data to help you identify time-tested professionals across the globe.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-exchange" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Flexibility</h4>
              <p>
                We provide multiple Payment terms and flexible Agreements to enable you to work the way you want.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-graduation-cap" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Expertise</h4>
              <p>
                Our team consists of highly experienced professionals with deep industry knowledge, ensuring you get top-tier solutions for your business.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-shield" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Security</h4>
              <p>
                We offer SafePay payment protection and your choice of preferred payment method for financial peace of mind.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-support" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Support</h4>
              <p>
                Our dedicated support team works 24/7 to resolve all your queries over the phone or email, no matter where you are located.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="service-box">
              <i className="fa fa-money" style={{ fontSize: "40px", color: "#fff" }}></i>
              <h4 style={{color:"#fff", fontWeight: "800"}}>Value</h4>
              <p>
                We have the lowest fees in the industry, providing you with maximum value at minimum cost.
              </p>
            </div>
          </div>
        </div>

        {/* Center Image */}
       

     
      </div>
    </div>
  );
};
