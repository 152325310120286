import React from "react";

export const Header = (props) => {
  return (
    <header id="header" className="text-center">
      <div className="intro" style={{ paddingTop: '10px', paddingBottom:'10px'}}>
      <div className="overlay" style={{ paddingTop: '50px' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="intro-text" >
                <h1>
                  {props.data ? props.data.title : ""}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
