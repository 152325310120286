import { Image } from "./image";
import React, { useRef } from "react";

export const Gallery = (props) => {
  const scrollableRef = useRef(null); // Create a reference for the scrollable container

  const scroll = (direction) => {
    const scrollAmount = 300; // Amount to scroll when button is clicked
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const scrollableRowStyle = {
    display: 'flex',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    padding: '10px 0',
    scrollbarWidth: 'none',
    alignItems: 'center', // Center items vertically
  };

  const cardStyle = {
    flex: '0 0 auto',
    width: '300px',
    margin: '0 10px',
    border: '1px solid #ddd',
    backgroundColor:'#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    textAlign: 'center',
  };

  const imageStyle = {
    width: '150px',
    height: '150px',
    objectFit: 'contain',
    marginTop: '10px'
  };

  const connectButtonStyle = {
    marginTop: '20px',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div id="portfolio" className="text-center" style={{background:"#6372ff"}}>
      <div className="container" >
        <div className="section-title">
          <h2 style={{color:"#fff", marginTop:"-60px"}}>We have worked with the following industries</h2>
          <p style={{color:"#fff"}}>
            We help businesses across industries, from startups to large corporations in
            India, USA, Australia, UK, UAE and worldwide
          </p>
        </div>
        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={() => scroll('left')} style={{ marginRight: '10px' }}>
            &lt; {/* Left Arrow */}
          </button>
          <div className="portfolio-items" ref={scrollableRef} style={scrollableRowStyle}>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} style={cardStyle}>
                  <img src={d.img} alt={d.title} style={imageStyle} />
                  <h3>{d.title}</h3>
                </div>
              ))
              : "Loading..."}
          </div>
          <button onClick={() => scroll('right')} style={{ marginLeft: '10px' }}>
            &gt; {/* Right Arrow */}
          </button>
        </div>
      </div>

      {/* Salesforce Developer Section */}
     
    </div>
  );
};
