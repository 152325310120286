import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./header";
import { Contact } from "./contact";
import { Navigation } from "./navigation";
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const headerData = {
    title: "",
    paragraph: "",
  };

  const contactData = {
    addresses: [
      "1) DLF Cybercity, Gurugram, India",
      "2) Unit 207A, 8 Cowper Street Parramatta NSW 2150 Australia",
      "3) California, USA"
    ],
    phone: "+91 97192 53679",
    email: "consult@marcomfreelancers.com",
    facebook: "fb.com",
    twitter: "twitter.com",
    linkedin: "https://www.linkedin.com/company/marcomfreelancers/about/?viewAsMember=true"
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple authentication check
    if (username === "admin" && password === "JaiMataDi") {
      onLogin(); // Call the login function passed as a prop
      setError("");
      navigate("/admin"); // Navigate to the admin page
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <div>
        <Navigation />
        <Header />

    <div style={{ padding: "20px", maxWidth: "400px", margin: "0 auto" }}>
      <h2>Admin Login</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ width: "100%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
          />
        </div>
        <button type="submit" style={{ padding: "10px 15px", borderRadius: "5px", border: "none", backgroundColor: "#6372ff", color: "#fff" }}>
          Login
        </button>
      </form>
    </div>
    <Contact data={contactData} />
    </div>
  );
};

export default Login;
