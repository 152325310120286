import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import { Header } from "./header";
import { Contact } from "./contact";
import { Navigation } from "./navigation";
import { db } from "../firebase"; // Import the db from your firebase.js
import { ref, onValue } from "firebase/database"; // Import ref and onValue

const Blog = () => {
    const [posts, setPosts] = useState([]);

    const contactData = {
        addresses: [
            "1) DLF Cybercity, Gurugram, India",
            "2) Unit 207A, 8 Cowper Street Parramatta NSW 2150 Australia",
            "3) California, USA"
        ],
        phone: "+91 97192 53679",
        email: "consult@marcomfreelancers.com",
        facebook: "fb.com",
        twitter: "twitter.com",
        linkedin: "linkedin.com"
    };

    useEffect(() => {
        const postsRef = ref(db, 'posts/');
        onValue(postsRef, (snapshot) => {
            const data = snapshot.val();
            const postsArray = [];
            for (let id in data) {
                postsArray.push({ id, ...data[id] }); // Push the blog post data with the ID
            }
            setPosts(postsArray);
        });
    }, []);

    const cardStyle = {
        backgroundColor: '#6372ff',
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 10px', // Adjusted margin for spacing
        textAlign: 'center',
        color: '#fff',
        height: '300px', // Increased height for the cards
        cursor: 'pointer', // Change cursor to pointer
    };

    const circleStyle = {
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto 20px auto',
        overflow: 'hidden', // Ensure the image fits within the circle
    };

    const imageStyle = {
        width: '100%', // Make image fill the circle
        height: '100%',
        objectFit: 'cover', // Maintain aspect ratio while filling the circle
    };

    return (
        <div>
            <Navigation />
            <Header />
            <div style={{ padding: "30px", maxWidth: "1200px", margin: "0 auto" }}>
                <h2 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>Blog Posts</h2>
                <div className="row">
                    {posts.map((post) => (
                        <div key={post.id} className="col-md-3"> {/* Change to col-md-3 for 4 cards per row */}
                            <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none' }}> {/* Link to BlogDetail page */}
                                <div style={cardStyle}>
                                    <div style={circleStyle}>
                                        <img src="img/blogs.jpeg" style={imageStyle} /> {/* Use the post image */}
                                    </div>
                                    <h3 style={{ color: '#fff', fontSize: '1.2em', margin: '10px 0' }}>{post.title}</h3>
                                    <p style={{ color: '#fff', margin: '0' }}>{post.date}</p>
                                    {/* Content and buttons are hidden */}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <Contact data={contactData} />
        </div>
    );
};

export default Blog;
