import React from "react";
import { useNavigate } from 'react-router-dom'; // For navigation

export const Services = (props) => {
  const navigate = useNavigate(); // Hook to navigate to OnJobSupport

  const cardStyle = {
    backgroundColor: '#6372ff',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    margin: '20px 0',
    textAlign: 'center',
    color: '#000',
  };

  const circleStyle = {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto 20px auto',
  };

  const imageStyle = {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
  };

  const buttonStyle = {
    display: 'block',
    margin: '0px auto', // Center the button
    padding: '12px 30px',
    backgroundColor: 'rgb(99, 114, 255)',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const handleButtonClick = () => {
    navigate('/onjobsupport'); // Navigate to OnJobSupport page
  };

  return (
    <div id="services" className="text-center" style={{ background: '#fff', padding: '20px 0' }}>
      <div className="container">
        <div className="section-title">
          <h2 style={{ color: "#000" }}>Our Services</h2>
          <p style={{ color: "#000" }}>Let your work be done by 10+ years of experienced professionals.</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div style={cardStyle}>
                    <div style={circleStyle}>
                      {d.img ? (
                        <img src={d.img} alt={d.name} style={imageStyle} />
                      ) : (
                        <i className={d.icon} style={{ fontSize: '50px' }}></i>
                      )}
                    </div>
                    <div className="service-desc">
                      <h3 style={{ color: '#fff' }}>{d.name}</h3>
                      <p style={{ color: '#fff' }}>{d.text}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>

        {/* Button to navigate to OnJobSupport */}
        <button onClick={handleButtonClick} style={buttonStyle}>
          On the Job Support
        </button>
      </div>
    </div>
  );
};

export default Services;
