import React, { useEffect } from 'react';
import { Header } from './header'; // Adjust path based on where Header is located
import { Contact } from './contact'; // Adjust path based on where Contact is located
import { Navigation } from './navigation';

export const OnJobSupport = () => {
  const headerData = {
    title: "",
    paragraph: ""
  };

  const footerData = {
    addresses: [
      "1) DLF Cybercity, Gurugram, India",
      "2) Unit 207A, 8 Cowper Street Parramatta NSW 2150 Australia",
      "3) California, USA"
    ],
    phone: "+91 97192 53679",
    email: "consult@marcomfreelancers.com",
    facebook: "fb.com",
    twitter: "twitter.com",
    linkedin: "https://www.linkedin.com/company/marcomfreelancers/about/?viewAsMember=true"
  };

  useEffect(() => {
    // Dynamically add the Google Tag Manager script
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8XNFCNPXTS";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8XNFCNPXTS');
    `;
    document.head.appendChild(script2);

    // Cleanup script elements when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <div className="on-job-support">
      <Navigation />
      <Header data={headerData} />

      <style>
        {`
          body, html {
            overflow-x: hidden; /* Prevents horizontal scrolling */
            margin: 0; /* Removes any default margin that may cause overflow */
            padding: 0;
            width: 100%;
          }
          
          .on-job-support {
            background-color: #f9f9f9;
            width: 100%;
          }

          .support-section {
            width: 100vw;
            background-color: #fff;
            padding: 30px;
          }

          h2 {
            // text-align: center;
            margin-bottom: 20px;
            font-size: 3.5rem;
            color: #333;
          }

          p {
            font-size: 1.6rem;
            line-height: 1.6;
            margin-bottom: 20px;
          }

          ul {
            margin-bottom: 20px;
          }

          ul li {
            font-size: 1.8rem;
            line-height: 1.6;
            margin-bottom: 10px;
          }

          h3 {
            font-size: 1.8rem;
            color: #333;
            margin-bottom: 10px;
          }

          .button-container {
            display: flex;
            justify-content: center; /* Centers the buttons horizontally */
            align-items: center;
          }

          .hire-btn, .query-btn {
            display: inline-block;
            width: auto;  /* Adjust width as necessary */
            padding: 15px;
            margin: 10px 0;
            font-size: 1.8rem;
            cursor: pointer;
            text-align: center;
            border: none;
            border-radius: 5px;
          }

          .hire-btn {
            background-color: #007bff;
            color: #fff;
          }

          .query-btn {
            background-color: #6c757d;
            color: #fff;
          }

          .hire-btn:hover, .query-btn:hover {
            opacity: 0.8;
          }

          @media (max-width: 768px) {
            .support-section {
              padding: 20px;
            }

            h2 {
              font-size: 2rem;
            }

            h3 {
              font-size: 1.5rem;
            }

            .hire-btn, .query-btn {
              font-size: 1rem;
              padding: 12px;
            }
          }

          /* Footer Section */
          #footer {
            background: #f6f6f6;
            padding: 0 0px 10px ;
          }
          #footer p {
            color: #888;
            font-size: 14px;
            margin: 0;
          }
          #footer a {
            color: #608dfd;
          }
          #footer a:hover {
            border-bottom: 2px solid #608dfd;
          }
        `}
      </style>

      <section className="container support-section">
        <h2 style={{ textAlign: 'center' }}>On the Job Support</h2>

        <p>
          At <strong>Marcomfreelancers</strong>, we understand the unique challenges that individuals face when working with platforms like Salesforce Marketing Cloud (SFMC), Salesforce, Web Development, and Marketo. Whether you're managing complex campaigns, fine-tuning workflows, or tackling integrations, we're here to support you every step of the way.
        </p>

        <h3>How We Help On the Job?</h3>
        <ul>
          <li><strong>Personalized Consultation:</strong> Share your specific tasks, projects, or challenges with us, whether it’s optimizing email campaigns, setting up automation, or troubleshooting platform issues.</li>
          <li><strong>Tailored Expert Support:</strong> After thoroughly understanding your needs, we match you with a certified consultant who specializes in the exact area you need assistance with. Our experts not only solve immediate issues but also provide guidance to help you excel in the future.</li>
          <li><strong>On-Demand Expertise:</strong> Whether you're stuck on a single task or need ongoing support, our consultants are ready to provide flexible solutions that align with your goals, helping you save time and maximize efficiency.</li>
        </ul>

        <h3>Flexible Payment Options</h3>
        <p>
          We offer a variety of payment tie-ups designed to suit your requirements. Whether you need support on an hourly, weekly, monthly, or yearly basis, we provide flexible solutions that fit your budget and project needs.
        </p>

        <button className="btn-primary hire-btn" onClick={() => window.location.href = "#contact"}>
          Hire a Consultant Now
        </button>

        <h3>Your Privacy is Our Priority</h3>
        <p>
          All information you share with us is treated with strict confidentiality. We guarantee that your details will remain private and will never be disclosed to third parties.
        </p>
        <button className="btn-secondary query-btn" onClick={() => {
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);
          if (isMobile) {
            window.open('mailto:consult@marcomfreelancers.com');
          } else {
            window.open('https://mail.google.com/mail/?view=cm&fs=1&to=consult@marcomfreelancers.com');
          }
        }}>
          Post my Query Now
        </button>

        <p>Let Us Help You Succeed. Reach out today to connect with the right expert and move forward with confidence!</p>
      </section>

      <div id="footer">

      </div>

      <Contact data={footerData} />
    </div>
  );
};

export default OnJobSupport;
