import React from "react";


  // Styles for Salesforce badges
  const badgeStyle = {
    margin: '10px',
  width: '100px', 
    objectFit: 'contain',
  };

  const salesforceContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Centers the images horizontally
    alignItems: 'center',     // Centers the images vertically
    flexWrap: 'wrap',         // Allows the images to wrap to the next line
    marginTop: '20px',
    maxWidth: '800px',        // Adjust to fit the number of images per line
    margin: '0 auto', 
  };
  const salesforceTitleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',

  };

export const Testimonials = (props) => {
  return (
    <div id="testimonials" style={{}} className="text-center">
       <div className="container" >
       <div className="section-title">
          <h2 style={{color:"#000",  marginTop:"-20px", textAlign:"center"}}> Scale your business by the help of our Certified Experts</h2>
          <div style={salesforceContainerStyle}>
            <img src="img/logos/1.jpeg" alt="JavaScript Developer I" style={badgeStyle} />
            <img src="img/logos/2.jpeg" alt="Platform Developer II" style={badgeStyle} />
            <img src="img/logos/7.jpeg" alt="Certified Associate" style={badgeStyle} />
            <img src="img/logos/4.jpeg" alt="Marketing Cloud Specialist" style={badgeStyle} />
            <img src="img/logos/5.jpeg" alt="Administrator" style={badgeStyle} />
            <img src="img/logos/6.jpeg" alt="Service Cloud Consultant" style={badgeStyle} />
            <img src="img/logos/3.jpeg" alt="Platform Developer I" style={badgeStyle} />
            <img src="img/logos/th.jpeg" alt="Certified Associate" style={badgeStyle} />
            <img src="img/logos/th (2).jpeg" alt="Certified Associate" style={badgeStyle} />
            <img src="img/logos/th (3).jpeg" alt="Certified Associate" style={badgeStyle} />
            <img src="img/logos/ths (4).jpeg" alt="Certified Associate" style={badgeStyle} />
            <img src="img/logos/th(6).jpeg" alt="Certified Associate" style={badgeStyle} />
          </div>
        </div>
  
      </div>
    </div>
  );
};
