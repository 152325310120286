import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';  
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { OnJobSupport } from "./components/OnJobSupport";  
import Blog from "./components/Blogs";
import BlogDetail from "./components/BlogDetail"; // Import BlogDetail component
import Admin from "./components/Admin";
import Login from "./components/Login";
import { db } from "./firebase"; // Import the initialized db
import { ref, onValue } from 'firebase/database'; // Import ref and onValue for Realtime Database

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [landingPageData, setLandingPageData] = useState({});
  const [posts, setPosts] = useState([]); // State for blog posts

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  useEffect(() => {
    setLandingPageData(JsonData);

    // Fetch posts from Firebase Realtime Database
    const fetchPosts = () => {
      const postsRef = ref(db, "posts"); // Reference to the "posts" node
      onValue(postsRef, (snapshot) => {
        const data = snapshot.val();
        const postsArray = [];

        for (let key in data) {
          postsArray.push({ id: key, ...data[key] }); // Include the post ID
        }

        setPosts(postsArray);
      }, (error) => {
        console.error("Error fetching posts: ", error);
      });
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // Dynamically add the Google Tag Manager script
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8XNFCNPXTS";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8XNFCNPXTS');
    `;
    document.head.appendChild(script2);

    // Cleanup script elements when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={(
          <>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Gallery data={landingPageData.Gallery} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
          </>
        )} />
        
        <Route path="/onjobsupport" element={<OnJobSupport />} />
        <Route path="/blog" element={<Blog posts={posts} />} /> {/* Pass posts to Blog */}
        <Route path="/blog/:id" element={<BlogDetail posts={posts} />} /> {/* Blog detail route */}

        {/* If the user is not logged in, show the Login component */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        {/* Admin route, accessible only when logged in */}
        <Route path="/admin" element={isLoggedIn ? <Admin isLoggedIn={isLoggedIn} /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
