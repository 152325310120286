// firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database'; // Import getDatabase for Realtime DB
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDuE2PAdyfKUk33UlzrhBBB5LaZx8B8WKQ",
    authDomain: "marcomfreelancers.firebaseapp.com",
    databaseURL: "https://marcomfreelancers-default-rtdb.firebaseio.com/", // Add your database URL
    projectId: "marcomfreelancers",
    storageBucket: "marcomfreelancers.appspot.com",
    messagingSenderId: "500830830496",
    appId: "1:500830830496:web:68e928e1b5177ca22caec5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getDatabase(app); // Initialize Realtime Database
const auth = getAuth(app);

export { db, auth };
