import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

// ContactUs component with existing form design
export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_odsn1ta", "template_x3m0wei", form.current, "THQdpUhFNquRG38Ek")
      .then(
        () => {
          alert("Message sent successfully!"); // Alert message
          form.current.reset(); // Reset the form fields
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Message failed to send. Please try again."); // Optional error alert
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="user_name"
              required
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="user_email"
              required
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              name="phone_number"
              required
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="test"
              className="form-control"
              placeholder="Company Name"
              name="company_name"
              required
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
      </div>
      <div className="form-group">
        <textarea
          name="message"
          className="form-control"
          rows="4"
          placeholder="Message"
          required
        />
        <p className="help-block text-danger"></p>
      </div>
      <div id="success"></div>
      <div className="button-container" style={{ display: 'flex', gap: '10px' }}>
        <button type="submit" className="btn btn-custom btn-lg">
          Send Message
        </button>
        <a
          href={`https://wa.me/${"919719253679"}?text=${encodeURIComponent("Hello, I would like to get in touch!")}`}
          className="btn btn-custom btn-lg whatsapp-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp"></i> Chat Now
        </a>
      </div>
    </form>
  );
};

// Contact component
export const Contact = (props) => {
  return (
    <div>
      <div id="contact" style={{ background: "#6372ff", padding: "20px" }} >
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              {/* Include the ContactUs form here */}
              <ContactUs />
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info ">
            <div className="contact-item">
              <h3>
                <b>Contact Info:</b>
              </h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> <b>Address</b>
                </span>
                {props.data ? (
                  <>
                    {props.data.addresses[0]} <br />
                    {props.data.addresses[1]} <br />
                    {props.data.addresses[2]}
                  </>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i>
                  <b>Phone</b>
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> <b>Email</b>
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
      <a
        href={`https://wa.me/${"919719253679"}?text=${encodeURIComponent("Hello, I would like to get in touch!")}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp"></i>
      </a>
    </li>
                  {/* <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p></p>
          <p>&copy; 2024 All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};
